<template>
  <v-dialog v-model="isModalOpen" width="550">
    <v-card v-if="currentItem">
      <v-toolbar class="py-2" flat>
        <v-toolbar-title>
          <h2 class="font-weight-regular text-h3">
            {{ $t("item_stock_details") }}
          </h2>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon class="mr-0">
          <v-icon @click="close()">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="">
        <div class="d-flex flex-row mx-4">
          <v-img
            class="me-4"
            max-height="200"
            max-width="200"
            v-if="currentItem?.image"
            :src="currentItem?.image?.url"
          />
          <v-img
            v-else
            class="me-4"
            src="@/assets/product.png"
            max-height="100"
            max-width="100"
          />
          <div class="">
            <p class="ma-1 font-weight-regular">{{ currentItem?.name }}</p>

            <p class="text--disabled mx-1 body-1">
              ID: {{ currentItem.code }} . Barcode : {{ currentItem.barcode }}
            </p>

            <p class="mt-4 mb-0 ml-1 font-weight-400 red--text text--lighten-1">
              {{ currentItem?.qte_in_hub ?? 0 }} {{ $t("items_in_stock") }}
            </p>
            <p class="mt-0 ml-1 font-weight-400 green--text text--lighten-1">
              + {{ currentItem?.reserved_qte ?? 0 }} {{ $t("reserved") }}
            </p>
          </div>
        </div>

        <v-row class="mt-9 mx-2">
          <v-data-table
            :headers="headers"
            :items="currentItem.orders"
            :items-per-page="5"
            class="elevation-0"
          ></v-data-table>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isModalOpen: Boolean,
    currentItem: Object,
    toggleModal: Function,
  },
  mounted: function () {},
  computed: {},
  data() {
    return {
      headers: [
        {
          text: this.$t("tracking_number"),
          align: "start",
          sortable: true,
          value: "tracking_number",
        },
        {
          text: this.$t("reserved_quantity"),
          align: "end",
          sortable: true,
          value: "qte_in_order",
        },
      ],
    };
  },

  methods: {
    close() {
      this.toggleModal();
    },
  },
};
</script>

<style scoped>
p {
  line-height: 1;
}
</style>
